import React, { useState } from "react"
import { Link } from "gatsby"
import loadable from "@loadable/component"
import { Container, Row, Col } from "react-bootstrap"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './assets/styles/_index.scss'
const ImageModule = loadable(() => import("../../modules/image-render"));
const FsLightbox = loadable(() => import("fslightbox-react"));
const Slider = loadable(() => import("react-slick"));
const { Site_Vars } = require("../../common/site/config");

const OffPlanDetailsBanner = (props) => {

    // Lightbox image popup
    const [propertyImage, setPropertyImage] = useState(false);
    const [propertyfloorImage, setPropertyFloorImage] = useState(false);
    // Lightbox image popup

    // Slider settings
    const [currentSlide, setCurrentSlide] = useState("");

    const handleAfterChange = (index) => {
        setCurrentSlide(index);
    };

    let settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        afterChange: handleAfterChange
    }
    // Slider settings

    // Handle scroll
    const handleClickScroll = (id) => {
        // const element = document.getElementById('property-desc-wrapper');
        const element = document.getElementById(id);

        if (element) {
            // element.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                behavior: 'smooth',
                top:
                    element.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    100,
            })
        }
    };
    // Handle scroll

    return (
        <>
            <section className="off-plan-details-banner-wrapper">
                <Slider className="off-plan-details-banner-slider" {...settings}>
                    {props?.images && props?.images?.strapi_json_value.length > 0 && (props.images.strapi_json_value).map((image, i) =>
                        image.url &&
                        <div className="new-homes-details-banner-slide">
                            <div className="new-homes-details-banner-img-zoom img-wrapper">
                                <ImageModule
                                    ImageSrc={image}
                                    altText={`image`}
                                    imagetransforms={props?.processedImages}
                                    renderer=""
                                    lazyLoading={i == 0 ? false : true}
                                    imagename={props?.imagename}
                                    strapi_id={props?.strapi_id} classNames="img-fluid"
                                />
                            </div>
                        </div>
                    )}
                </Slider>

                <div className="overlay-bg"></div>

                <ul className="list-inline off-plan-details-banner-zoom-wrapper d-md-flex align-items-center d-none">
                    <li className="list-inline-item">
                        <button className="banner-arrow d-flex align-items-center justify-content-center" onClick={() => setPropertyImage(!propertyImage)}><i className="icon icon-property-gallery"></i> {currentSlide + 1}/{(props.propImg).length}</button>
                    </li>
                    {props.floorPlanImg?.length > 0 &&
                        <li className="list-inline-item">
                            <button className="d-flex align-items-center justify-content-center" onClick={() => setPropertyFloorImage(!propertyfloorImage)}>Floorplans</button>
                        </li>
                    }
                    <li className="list-inline-item">
                        <button className="d-flex align-items-center justify-content-center" onClick={() => handleClickScroll("property-details-map-wrapper")}>Map</button>
                    </li>
                </ul>

                <div className="off-plan-details-banner-slider-wrapper d-xl-flex align-items-end d-none">
                    <Container className="off-plan-details-banner-container">
                        <Row>
                            <Col>
                                <h2>{props.display_address}</h2>
                                <p className="off-plan-details-price">{props.price_qualifier} {Site_Vars.default_currency}{props?.price?.toLocaleString()} {props?.max_price ? ` - ${Site_Vars.default_currency}${props?.max_price?.toLocaleString()}` : ''}</p>
                                <p className="off-plan-details-title">{props.title}</p>
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a className="button-sec button-sec-dubai-red" href="javascript:;" onClick={() => handleClickScroll("off-plan-detail-sidebar")}>Register Interest</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link className="button-sec button-sec-dubai-red" to="">Download Brochure</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            <div className="d-xl-none off-plan-details-banner-content">
                <Container>
                    <Row>
                        <Col>
                            <h2>{props.display_address}</h2>
                            <p className="off-plan-details-price">{props.price_qualifier} {Site_Vars.default_currency}{props?.price?.toLocaleString()} {props?.max_price ? ` - ${Site_Vars.default_currency}${props?.max_price?.toLocaleString()}` : ''}</p>
                            <p className="off-plan-details-title">{props.title}</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Property Lightbox popup */}
            <FsLightbox
                toggler={propertyImage}
                sources={props.propImg}
                type="image"
            />
            <FsLightbox
                toggler={propertyfloorImage}
                sources={props.floorPlanImg}
                type="image"
                types={[null]}
            />
            {/* Property Lightbox popup */}
        </>
    )
}

export default OffPlanDetailsBanner