import React from "react"
import { graphql } from 'gatsby'
import loadable from "@loadable/component"
import { Container, Row, Col } from "react-bootstrap"
import Header from "../components/Header/Header"
import PatternBanner from "../components/PatternBanner/PatternBanner"
import FilterSearch from "../components/FilterSearch/FilterSearch"
import CareersPositionDubai from "../components/CareersPositionDubai/CareersPositionDubai"
import FaqModule from "../components/FaqModule/FaqModule"
import PropertyBannerModule from "../components/PropertyBannerModule/PropertyBannerModule"
import AreaGuideDetailsBannerDubai from "../components/AreaGuideDetailsBannerDubai/AreaGuideDetailsBannerDubai"
import Footer from "../components/Footer/Footer"
import OffPlanDetailsBanner from "../components/OffPlanDetailsBanner/OffPlanDetailsBanner"
import OffPlanKeyDetails from "../components/OffPlanKeyDetails/OffPlanKeyDetails"
import "../styles/main.scss"
import JourneyImg from "../images/area_guide_detail_banner_img.png"

const propertyImg = [JourneyImg, JourneyImg]

const TestPage = ({data, footertag}) => {
    const PageData = data?.strapiPage

    return (
        <>
            <Header headerDubai="header-dubai" />
                <div className="layout-padding-top"></div>

                {/* <OffPlanDetailsBanner /> */}

                <Container>
                  <Row>
                    <Col xl={7}>
                      <br />
                      {/* <OffPlanKeyDetails /> */}
                    </Col>
                  </Row>
                </Container>

            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
              return (
                <>
                  {module.strapi_component === "page-modules.text-module" && <PatternBanner patternDubai="pattern-dubai" patternBgImg={JourneyImg}
                      tag={module.layout === "background_secondary_color" ? "blue" : module.layout === "background_primary_color" ? "brown" : ''}
                      {...module}
                  />}
                </>
              )
            })}

            <FilterSearch filterDubai="filter-dubai" />

            {/* <CareersPositionDubai /> */}

            {/* <FaqModule /> */}

            <PropertyBannerModule
              propertyBannerDubai="property-banner-dubai"
              images={{
                "strapi_json_value": [
                    {
                        "srcUrl": "https://assets.reapit.net/loc/live/pictures/JSC/22/JSC220607_02.jpg",
                        "url": "https://ggfx-miratheme.s3.eu-west-2.amazonaws.com/i.dev/property/21/images/loc/live/pictures/JSC/22/JSC220607_02_GGFX_V_1.jpg"
                    },
                    {
                        "srcUrl": "https://assets.reapit.net/loc/live/pictures/JSC/22/JSC220607_04.jpg",
                        "url": "https://ggfx-miratheme.s3.eu-west-2.amazonaws.com/i.dev/property/21/images/loc/live/pictures/JSC/22/JSC220607_04_GGFX_V_1.jpg"
                    },
                    {
                        "srcUrl": "https://assets.reapit.net/loc/live/pictures/JSC/22/JSC220607_01.jpg",
                        "url": "https://ggfx-miratheme.s3.eu-west-2.amazonaws.com/i.dev/property/21/images/loc/live/pictures/JSC/22/JSC220607_01_GGFX_V_1.jpg"
                    }
                ]
              }}
              propImg={propertyImg}
              display_address="Albert Court, Bridgtown, Cannock, Staffordshire, WS11"
              price_qualifier="Asking price"
              price="115,000"
              title="2 bedroom apartment for sale"
            />

            {/* <AreaGuideDetailsBannerDubai bannerImg={JourneyImg} /> */}

            <Footer
                popularSearch={PageData?.select_popular_search?.title}
                footertag={footertag}
                footerDubai="footer-dubai"
            />
        </>
    )
}

export default TestPage

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
              ...ImageAndContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_MODULE {
              ...TextModuleFragment
            }
        }
    }

    
    strapiSiteConfig {
        twitter_link
        instagram_link
        linkedin_link
        facebook_link
        add_contact_details {
          phone
        }
      }
  }
`