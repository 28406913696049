import React from "react"
import { Container, Row, Col, Accordion } from "react-bootstrap"
// import { Link } from "gatsby"
import './assets/styles/_index.scss'
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const FaqModule = (props) => {
    return (
        <div className="faq-module-wrapper">
            <Container>
                <Row>
                    <Col>
                        <h3 className="faq-module-heading">{props.title}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Accordion className="faq-module-accordion">
                            {props.add_faq.length > 0 && props.add_faq.map((item, index) => (
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{item.title}</Accordion.Header>
                                    <Accordion.Body>
                                        <ContentModule Content={item.content?.data?.content} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FaqModule